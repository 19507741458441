@use '@angular/material' as mat;

/* You can add global styles to this file, and also import other style files */
@import 'theme.scss';
@import './assets/styles/variables.scss';

$font-family: 'Montserrat', Roboto, "Helvetica Neue", sans-serif !important;

html, body { height: 100%; }
body { margin: 0; transform: scale(1); transform-origin: 0 0; }

.mat-calendar-body {
  .mat-calendar-body-cell {
    position: absolute;
    height: 100%;
  }
}

.ngx-mat-calendar-body {
  .mat-calendar-body-cell {
    position: relative;
  }
}
.mat-drawer-container {
  background-image: url("assets/bg-login.png");
  background-color: var(--soft-gray);
  background-size: cover;
}

.sections-container {
  padding: 16px;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.82) !important;
}

.cdk-overlay-pane {
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ccc;
  }

  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  ::-webkit-scrollbar-corner {
    background-color: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb:vertical {
    min-height: 50px;
  }
}

$custom-typography: mat.define-typography-config(
  $font-family: $font-family
);
@include mat.core($custom-typography);

.filter-menu {
  display: flex;
  align-items: center;
}

.center-date{
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

@media (max-width: 1024px) {
  .actions-container {
    margin-bottom: 16px;
  }
}

.actions-container {
  .filter-btn, .icon-btn {
    margin-bottom: auto;
  }

  .filter-btn {
    height: 32px;
    .mat-button-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;
      height: 32px;
    }
  }

  .icon-btn {
    width: 28px;
    height: 28px;
    margin-top: 2px;

    display: flex;
    justify-content: center;
    align-items: center;

    mat-icon {
      width: 20px;
      height: 20px;
      line-height: 0;
      font-size: 20px;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    line-height: 32px;
  }
}

.mat-autocomplete-panel{
  min-width: fit-content !important;
}

.dialog-divider {
  margin: 0 -24px !important;
}

.mat-dialog-title.dialog-title-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;

  h1 {
    margin: 0;
  }

  .close-modal-btn {
    color: var(--secondary-color);
  }
}

.mat-tooltip {
  margin: 8px 16px!important;
}
.mat-tooltip-panel .mat-tooltip-show:hover {
  display: none;
  pointer-events: none;
}

.dashboard-container {
  padding: 8px 24px 0 24px;
  background: var(--white-2);
  min-height: calc(100vh - 74px);

  .divider {
    margin: 0 -16px 16px -16px;
    background: var(--gray);
  }
}

/* Custom Styles */
.yellow-text {
  color: var(--secondary-color);
}

/* MatSnackbar Styles */
.custom-snackbar {
  position: relative;
  top: 24px;
}

.yellow-snackbar {
  background-color: var(--soft-gray);
  color: var(--secondary-color) !important;
}

.yellow-snackbar button {
  background-color: var(--soft-gray);
  color: var(--secondary-color) !important;
  border: none;
}

.red-snackbar {
  background: var(--main-red);
  color: var(--main-white);
}
.red-snackbar button {
  background-color: var(--main-red);
  color: var(--main-white) !important;
  border: none;
}

.green-snackbar {
  background: var(--main-green);
  color: var(--main-white);
}
.green-snackbar button {
  background-color: var(--main-green);
  color: var(--main-white);
  border: none;
}

/* MatTable Stules */
.custom-paginator {
  background: var(--white-2);
  color: var(--gray);
  font-weight: 500;
  //margin-bottom: .75rem;

  button {
    color: var(--secondary-color);
  }


  .mat-form-field {
    .mat-form-field-underline {
      width: 100%;
      background-color: var(--secondary-color);
    }

    .mat-form-field-ripple {
      background-color: var(--secondary-color) !important;
    }

    .mat-focused {
      .mat-select-arrow {
        color: var(--secondary-color);
      }
    }
  }

  mat-select {
    .mat-select-value, .mat-select-arrow {
    }
  }
}

.custom-table {
  width: 100%;

  .mat-table {
    background: var(--white-2);
  }

  .mat-header-row {
    height: 32px;
  }

  .mat-header-cell {
    border-bottom: 2px solid var(--secondary-color);
  }

  .mat-sort-header-container {
    .ng-trigger-arrowPosition {
      color: var(--secondary-color) !important;
    }
  }

  .mat-cell {
    font-weight: 500;
  }

  th {
    color: var(--gray);
    font-size: .9rem;
  }

  tr {
    .mat-cell {
      border-bottom: 1px solid var(--gray);
    }
  }

  .mat-header-cell:last-of-type, .mat-cell:last-of-type {
    padding-right: 8px;
  }
  .mat-header-cell:first-of-type, .mat-cell:first-of-type {
    padding-left: 8px;
  }
}

/* MatProgressBar Styles */
.custom-progress-bar {
  height: 16px !important;
  border-radius: 16px;

  .mat-progress-bar-fill::after {
    background-color: var(--main-color);
    border-radius: 16px;
  }

  .mat-progress-bar-element {
    border-radius: 12px 0 0 12px;
    height: 95% !important
  }
}

/* MatSpinner Styles */
.custom-spinner {
  circle {
    stroke: var(--secondary-color);
  }
}

.spinner-white {
  circle {
    stroke: var(--soft-white);
  }
}

/* MatCheckbox Styles */

.custom-checkbox.mat-checkbox-checked {
  .mat-checkbox-background {
    background-color: var(--main-color);
  }
}

.mat-checkbox-checked.mat-accent .mat-checkbox-ripple .mat-ripple-element {
  background-color: var(--secondary-color) !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: var(--secondary-color) !important;
}

.mat-checkbox-ripple .mat-ripple-element,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: var(--secondary-color) !important;
}


/* MatButton Styles*/
.red-button, .green-button, .custom-button {
  &:disabled {
    background: var(--soft-white);
  }
  color: var(--main-white);
}

.red-button {
  background: var(--soft-red);
}

.green-button {
  background: var(--main-color);
}

.custom-button {
  background: var(--button-primary-color);
  //background: var(--secondary-color);
}

.white-button {
  &:disabled {
    background: var(--soft-white);
  }

  color: var(--secondary-color);
  background: var(--main-white);
}

.custom-secondary-button {
  background: var(--button-secondary-color);
  color: var(--main-white);
}

.breadcrumb-action-button {
  height: 32px;
  display: flex !important;
  align-items: center;
}

.custom-col {
  display: flex;
  align-items: center;

  mat-icon {
    margin-left: 4px;
    font-size: 20px;
    height: 20px;
    width: 20px;
  }
}

.upload-document-dialog {
  .mat-dialog-container {
    margin: 0;
    padding: 0;
    background: var(--main-white);

    .mat-dialog-title {
      padding: 8px 16px;
    }

    .mat-dialog-actions {
      margin-bottom: 0;
    }

    .mat-dialog-content {
      margin: 0;
      padding: 24px 38px;
    }

    .row {
      --bs-gutter-x: 0;
    }
  }
}

.select-account-option {
  font-size: .8rem !important;
}

.mfa-input {
  max-width: 280px;

  mat-form-field ::ng-deep {
    .mat-form-field-flex {
      border: 1px solid;
      text-align: center;
      font-size: 1.2rem;

      input {
        letter-spacing: 8px;
      }
    }
  }
}

/* MatDialog Styles */
.detailed-modal {
  mat-dialog-container {
    background-color: var(--white-2);
  }
}

.custom-modal {

  mat-dialog-container {
    background-color: var(--white-2);
  }

  .close-modal-btn {
  position: relative;
  left: 12px;
}

  .mat-dialog-title {
    font-size: 1rem !important;
    font-weight: bold !important;
  }
  mat-dialog-container {
    padding-top: 12px;
  }
}

.errors-form-field {
  .mat-form-field-wrapper {
    padding-bottom: 0 !important;

    .mat-form-field-underline {
      position: initial !important;
      display: block;
      margin-top: -1px;
    }

    .mat-form-field-subscript-wrapper,
    .mat-form-field-ripple {
      position: initial !important;
      display: table;
    }

    .mat-form-field-subscript-wrapper {
      min-height: calc(1em + 1px);
    }
  }
}


/* Table Status colors */

.created, .registered, .pending {
  color: black;
}

.completed, .approved, .done {
  color: var(--main-blue);
}

.cancelled, .error, .rejected {
  color: var(--main-red-1);
}

.refund, .reversed, .inprogress {
  color: var(--main-orange);
}

.open-menu-filter-rules {
  .mjx-home-filter {
    @media (min-width: 1501px) {
      .filter-field {
        max-width: 245px;
      }
    }
  }

  @media (min-width: 542px) and (max-width: 786px) {
    .filter-field {
      width: 100% !important;
    }
  }

  @media (min-width: 787px) and (max-width: 1052px) {
    .filter-field {
      width: 50% !important;
    }
  }

  @media (min-width: 1053px) and (max-width: 1242px) {
    .filter-field {
      width: 33% !important;
    }
  }

  @media (min-width: 1243px) and (max-width: 1500px) {
    .filter-field {
      width: 25% !important;
    }
  }

  @media (min-width: 1501px) and (max-width: 1760px) {
    .filter-field {
      width: 20% !important;
    }
  }

  @media (min-width: 1761px) and (max-width: 1996px) {
    .filter-field {
      width: 16.66666667% !important;
    }
  }

  @media (min-width: 1997px) and (max-width: 2250px) {
    .filter-field {
      width: 14.28571429% !important;
    }
  }

  @media (min-width: 2250px) {
    .filter-field {
      width: 12.5% !important;
    }
  }
}

.closed-menu-filter-rules {
  .mjx-home-filter {
    @media (min-width: 1243px) {
      .filter-field {
        max-width: 245px;
      }
    }
  }

  @media (min-width: 542px) and (max-width: 786px) {
    .filter-field {
      width: 50% !important;
    }
  }

  @media (min-width: 787px) and (max-width: 1052px) {
    .filter-field {
      width: 33% !important;
    }
  }

  @media (min-width: 1053px) and (max-width: 1242px) {
    .filter-field {
      width: 25% !important;
    }
  }

  @media (min-width: 1243px) and (max-width: 1500px) {
    .filter-field {
      width: 20% !important;
    }
  }

  @media (min-width: 1501px) and (max-width: 1760px) {
    .filter-field {
      width: 16.66666667% !important;
    }
  }

  @media (min-width: 1761px) and (max-width: 1996px) {
    .filter-field {
      width: 14.28571429% !important;
    }
  }

  @media (min-width: 1997px) and (max-width: 2250px) {
    .filter-field {
      width: 12.5% !important;
    }
  }

  @media (min-width: 2250px) {
    .filter-field {
      width: 11.11% !important;
    }
  }
}

.mat-drawer-side.mat-drawer-opened {
  background-color: red;
}
